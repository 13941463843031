<template lang="pug">
  .confit-items-table
    .common
      AppTooltip.m-r-10(:title="$t(`maestro.configs_page.tooltip.${configKey}`)")
      .title {{ $t("maestro.configs_page.common") }}
      AppToggle.toggle(
        :value="commonConfigItem.value"
        @change="handleChange(commonConfigItem, $event)"
      )
    .config-items
      .config-item-wrapper(
        v-for="configItem in mappedConfigItems"
        v-if="configItem.key !== 'common'"
        :key="configItem.key"
      )
        .config-item(:title="configItem.key")
          .title {{ configItem.key }}
          AppToggle.toggle(
            :value="configItem.value"
            @change="handleChange(configItem, $event)"
          )
</template>

<script>
  import { map, find } from "lodash-es"
  import { isConfigEnabled } from "@/helpers/config-items"

  export default {
    components: {
      AppToggle: () => import("@/components/elements/AppToggle"),
      AppTooltip: () => import("@/components/elements/AppTooltip")
    },

    props: {
      configKey: String,
      configItems: {
        type: Array,
        default: () => new Array()
      }
    },

    computed: {
      mappedConfigItems({ configItems }) {
        return map(configItems, item => {
          return {
            key: item.key.split(".").at(0),
            value: isConfigEnabled(item)
          }
        })
      },

      commonConfigItem({ mappedConfigItems }) {
        return find(mappedConfigItems, { key: "common" })
      }
    },

    methods: {
      handleChange({ key }, { value }) {
        const configItem = find(this.configItems, item => item.key === `${key}.${this.configKey}`)
        this.$emit("change", { payload: { ...configItem, value }, configKey: this.configKey })
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .confit-items-table
    .common
      align-item: center
      border-bottom: 1px solid $default-purple-light-line
      justify-content: center
      padding: 20px 0

    .common,
    .config-item
      align-items: center
      display: flex
      height: 30px

      .title
        margin-right: 20px

    .config-items
      display: flex
      flex-wrap: wrap

      .config-item-wrapper
        display: flex
        justify-content: center
        width: 50%

        .config-item
          border-bottom: 1px solid $default-purple-light-line
          justify-content: space-between
          padding: 5px
          width: 50%

          .title
            overflow: hidden
            max-width: 80%
            text-overflow: ellipsis
            white-space: nowrap
</style>
